// Barra principal
.fdx-menu.lateral {
  float: right;
  margin-top: 10px;

  // TABLET
  @include mq(m) {
    margin-top: 20px;
  }

  // MOBILE
  @include mq(s) {
    display: none;
  }

  li {
    float: left;
    position: relative;
  }

  // Links geral
  a {
    display: inline-block;
    width: 100%;
    font-family: $fonte_geral;
    font-size: 14px;
    line-height: 100%;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.3s ease-out;

    // TABLET
    @include mq(m) {
      font-size: 12px;
    }

    &:hover {
      color: $cor_secundaria;
    }
  }
}

// 1 Level
.lateral .fdx-menu-1-level {
  float: left;
  width: 100%;

  // Item
  > li {
    line-height: 0;
    padding: 0 10px;

    // TABLET
    @include mq(m) {
      padding: 0 5px;
    }

    // Remove o padding do primeiro item
    &:first-child {
      padding-left: 0px;
    }

    // Remove o padding do último item
    &:last-child {
      padding-right: 0px;
    }

    // Link
    > a {
      position: relative;
      z-index: 2;
      padding: 10px;
      color: $cor_fonte_escura;
      text-transform: uppercase;

      span {
        position: absolute;
        display: block;
        width: 12px;
        height: 12px;
        border-color: $cor_primaria;
        border-style: solid;
        opacity: 0;
        transform: scale(1.3);
        transition: 0.3s ease-out;

        // TABLET
        @include mq(m) {
          width: 8px;
          height: 8px;
        }
      }
      &:hover span {
        opacity: 1;
        transform: scale(1);
      }
      $bw: 1px;
      span:nth-child(1) {
        top: 0;
        left: 0;
        border-width: $bw 0 0 $bw;
      }
      span:nth-child(2) {
        top: 0;
        right: 0;
        border-width: $bw $bw 0 0;
      }
      span:nth-child(3) {
        bottom: 0;
        left: 0;
        border-width: 0 0 $bw $bw;
      }
      span:nth-child(4) {
        bottom: 0;
        right: 0;
        border-width: 0 $bw $bw 0;
      }
    }
  }
}

// 2 Level
.lateral .fdx-menu-2-level {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 1;
  width: 250px;
  padding: 10px 0;
  background: $cor_secundaria;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0) translate3d(0, -10px, 0);
  transform-origin: top left;
  transition: 0.3s ease;

  // Ponta
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -9px;
    left: 30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 10px 15px;
    border-color: transparent transparent $cor_secundaria transparent;
  }
  &::after {
    content: "";
    display: none;
    position: absolute;
    z-index: -1;
    top: -25px;
    left: 0;
    width: 100%;
    height: 25px;
  }

  // Item
  li {
    float: left;
    width: 100%;

    // Link
    a {
      padding: 10px 20px;
      color: #fff;
    }
  }
}
.lateral .fdx-menu-1-level > li:hover > .fdx-menu-2-level {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1) translate3d(0, 0, 0);

  &::after {
    display: block;
  }
}

// 3 Level
.lateral .fdx-menu-3-level {
  position: absolute;
  top: -10px;
  right: -250px;
  width: 250px;
  padding: 10px 0;
  background: $cor_secundaria;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  opacity: 0;
  visibility: hidden;
  transform: translate(-20px, 0);
  transition: 0.3s ease;
}
.lateral .fdx-menu-2-level > li:hover > .fdx-menu-3-level {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}
