// Seção
.home-sobre {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0;
  padding-top: 20px;
}

// Esquerda
.home-sobre-left {
  float: left;
  width: 50%;
  height: 600px;
  overflow: hidden;
  background: $cor_fundo2;
  padding: 40px;

  // TABLET
  @include mq(m) {
    height: auto;
  }

  // MOBILE
  @include mq(s) {
    width: 100%;
    padding: 20px;
    height: auto;
  }

  // Título
  .titulo {
    // MOBILE
    @include mq(s) {
      text-align: center;
    }

    span {
      // MOBILE
      @include mq(s) {
        margin: 0 auto;
        right: 0;
      }
    }

    &::after {
      // MOBILE
      @include mq(s) {
        margin: 0 auto;
      }
    }
  }

  // Texto
  .texto {
    font-size: 18px;
    font-weight: 300;
    color: $cor_fonte_escura;

    b,
    strong {
      font-weight: 600;
    }

    // MOBILE
    @include mq(s) {
      font-size: 16px;
    }
  }

  // Botão
  .btn-container {
    // MOBILE
    @include mq(s) {
      margin-top: 0;
      text-align: center !important;
    }
  }
}

// Direita (Foto)
.home-sobre-right {
  float: left;
  width: 50%;
  overflow: hidden;
  position: relative;

  // MOBILE
  @include mq(s) {
    width: 100%;
  }

  img {
    filter: grayscale(100%);
    transition: 0.7s ease-out;
  }
  &:hover img {
    transform: scale(1.1);
  }
}

// Linhas da foto
.home-sobre-foto-lines {
  position: absolute;
  top: 40px;
  right: 40px;
  bottom: 40px;
  left: 40px;

  // MOBILE
  @include mq(s) {
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
  }

  span {
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    border-color: rgba(#fff, 0.5);
    border-style: solid;
    transition: 0.7s ease-out;
  }
  $bw: 1px;
  span:nth-child(1) {
    top: 0;
    left: 0;
    border-width: $bw 0 0 $bw;
  }
  span:nth-child(2) {
    top: 0;
    right: 0;
    border-width: $bw $bw 0 0;
  }
  span:nth-child(3) {
    bottom: 0;
    left: 0;
    border-width: 0 0 $bw $bw;
  }
  span:nth-child(4) {
    bottom: 0;
    right: 0;
    border-width: 0 $bw $bw 0;
  }
  &:hover span {
    width: 50%;
    height: 50%;
  }
}
