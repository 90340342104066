// Título
.titulo {
  float: left;
  width: 100%;
  position: relative;
  font-family: $fonte_titulo;
  font-size: 36px;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: 1.5;
  color: $cor_fonte_escura;
  text-align: center;
  text-transform: none;
  margin-bottom: 40px;
  padding: 15px 0;

  // TABLET
  @include mq(m) {
    font-size: 32px;
  }

  // MOBILE
  @include mq(s) {
    font-size: 24px;
    margin-bottom: 30px;
    padding: 10px 0;
  }

  span {
    position: absolute;
    //z-index: -1;
    top: -5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0.1;
    font-size: 50px;

    // TABLET
    @include mq(m) {
      top: -3px;
      font-size: 40px;
    }

    // MOBILE
    @include mq(s) {
      top: -3px;
      font-size: 30px;
    }
  }

  // Linhas
  // &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80px;
    height: 2px;
    background: $cor_primaria;
  }
  /* &::before {
    top: 0;
  } */
  &::after {
    bottom: 0;
  }

  &.left {
    text-align: left;

    span {
      margin: 0;
      right: initial;
    }

    //&::before,
    &::after {
      margin: 0;
    }
  }
}

// Banner título
.banner-titulo {
  float: left;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 70px;
  padding-bottom: 80px;

  // MOBILE
  @include mq(s) {
    padding-top: 50px;
    padding-bottom: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }

  // Máscara
  .mascara {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($cor_secundaria, 0.9);
    opacity: 0.7;
  }

  .titulo {
    color: #fff;
    padding: 0;
    margin: 0;
    text-shadow: 2px 2px 2px rgba(#000, 0.2);

    &::after {
      display: none;
    }
  }
}

// Subtítulo
.subtitulo {
  float: left;
  width: 100%;
  position: relative;
  font-family: $fonte_geral;
  font-size: 20px;
  line-height: 1.2;
  font-weight: bold;
  color: $cor_fonte_escura;
  margin-bottom: 20px;

  &.center {
    text-align: center;
  }
}

// Texto geral
.texto {
  font-family: $fonte_geral;
  font-size: 16px;
  line-height: 1.5;
  color: $cor_fonte_clara;

  &.center {
    text-align: center;
  }

  b,
  strong {
    font-weight: bold;
    color: $cor_fonte_escura;
  }

  // Unresets para CKEDITOR
  em {
    font-style: italic !important;
  }
  ul {
    list-style: disc outside none !important;
    padding-left: 15px;
  }
  ol {
    list-style: decimal outside none !important;
    padding-left: 15px;
  }
  li {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 20px;
    line-height: 1.5;
  }
  img {
    display: inline-block;
  }
}

// Texto (Sem registros)
.empty {
  text-align: center;
  min-height: 100px;

  span {
    font-family: $fonte_geral;
    font-size: 24px;
    color: $cor_fonte_escura;
    margin-bottom: 10px;
  }

  p {
    font-family: $fonte_geral;
    color: $cor_fonte_clara;
  }

  b {
    font-weight: bold;
    color: $cor_fonte_escura;
  }
}

// Links
a,
a:hover,
a:visited,
a:focus,
a:active {
  outline: none;
  text-decoration: none;
}
.link {
  font-family: $fonte_geral;
  transition: 0.2s;
  color: $cor_primaria;

  &:active,
  &:focus,
  &:visited {
    color: $cor_primaria;
  }

  &:hover {
    color: $cor_secundaria !important;
  }
}
