// Lista
.lista-parceiros {
  float: left;
  width: 100%;
}

// Bloco
.parceiro-item {
  display: block;
  border: 1px solid rgba(#000, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;

  // Imagem
  img {
    transition: 0.3s ease-out;
    filter: grayscale(100%);
  }
  &:hover {
    position: relative;
    z-index: 2;

    img {
      transform: scale(1.1);
      filter: grayscale(0%);
    }
  }
}
