// Banner do topo
.institucional-banner {
  float: left;
  width: 100%;
  height: 200px;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  // TABLET
  @include mq(m) {
    height: 150px;
  }

  // MOBILE
  @include mq(s) {
    height: 100px;
  }
}

/* Segura o texto das páginas instituicionais para evitar que fiquem com 100% de largura e prejudique a leitura */
.segura-texto {
  // DESKTOP
  @include mq(l) {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

// Foto sobre
.foto-sobre {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;

  figure {
    display: inline-block;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    overflow: hidden;
    border: 5px solid $cor_fundo2;

    // MOBILE
    @include mq(s) {
      width: 200px;
      height: 200px;
    }

    img {
      transition: 0.5s ease-out;
    }
    &:hover img {
      transform: scale(1.2);
    }
  }
}
