// Botão padrão
.btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: auto;
  background: none;
  border: 1px solid #666;
  border-radius: 0px;
  font-family: $fonte_geral;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #333;
  text-align: center;
  padding: 15px 30px;
  outline: none;
  cursor: pointer;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition: 0.3s ease-out;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #666;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: 0.3s ease-out;
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: #fff;

    &::before {
      transform: scaleX(1);
    }
  }

  // Inativo
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  i {
    margin-right: 10px;
  }

  // Botão primário
  &.btn-primario {
    border-color: $cor_primaria;
    color: $cor_primaria;

    &::before {
      background: $cor_primaria;
    }

    .seta {
      background: $cor_primaria;
      color: $cor_primaria;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: #fff;

      &::before {
        transform: scaleX(1);
      }

      .seta {
        background: #fff;
        color: #fff;
      }
    }
  }

  // Botão secundário
  &.btn-secundario {
    border-color: $cor_secundaria;
    color: $cor_secundaria;

    &::before {
      background: $cor_secundaria;
    }

    .seta {
      background: $cor_secundaria;
      color: $cor_secundaria;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: #fff;

      &::before {
        transform: scaleX(1);
      }

      .seta {
        background: #fff;
        color: #fff;
      }
    }
  }

  // Botão branco
  &.btn-white {
    border-color: #fff;
    color: #fff;

    &::before {
      background: #fff;
    }

    .seta {
      background: #fff;
      color: #fff;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $cor_secundaria;

      &::before {
        transform: scaleX(1);
      }

      .seta {
        background: $cor_secundaria;
        color: $cor_secundaria;
      }
    }
  }

  // Botão pequeno
  &.btn-sm {
    padding: 10px 20px;
    font-size: 12px;
  }

  // Botão grande
  &.btn-lg {
    padding: 20px 40px;
    font-size: 16px;
  }

  // Seta
  .seta {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    width: 30px;
    height: 1px;
    background: #666;
    color: #666;
    transition: 0.3s;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0px;
      top: -4px;
      width: 9px;
      height: 9px;
      border-top: 1px solid currentColor;
      border-right: 1px solid currentColor;
      transform: rotate(45deg);
    }
  }
  &:hover span,
  &:focus span,
  &:active span,
  &.active span {
    animation: animacao-seta 1s;
    animation-delay: 0.25s;
  }
}

@keyframes animacao-seta {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  25% {
    transform: translate3d(20px, 0, 0);
    opacity: 0;
  }
  26% {
    transform: translate3d(-20px, 0, 0);
    opacity: 0;
  }
  50% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

// Botão container
.btn-container {
  float: left;
  width: 100%;
  margin-top: 20px;
  text-align: center;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}
