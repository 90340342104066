// Seção
.home-depoimentos {
  background: $cor_fundo2;

  // Botão
  .btn-container {
    margin-top: 40px;
  }
}

// Carrosel
.carrosel-depoimentos {
  // Depoimento item
  .depoimento-item {
    width: 100%;
    margin: 10px 20px;

    // MOBILE
    @include mq(s) {
      margin: 10px;
    }
  }
}
