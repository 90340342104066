// Lista vídeos
.lista-videos {
  float: left;
  width: 100%;
}

// Bloco vídeo
.bloco-video {
  position: relative;
  margin-bottom: 20px;
  background: $cor_secundaria;
  padding: 10px;
  border-radius: 0px;
}
.bloco-video-wrapper {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
    padding-top: percentage(9 / 16);
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border: 0;
  }
}
.bloco-video-titulo {
  margin: 10px 0;
  line-height: 1.4;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
