// Container
.campo-container {
  position: relative;
  margin-bottom: 20px;

  // Label
  label {
    display: inline-block;
    font-weight: 500;
    color: $cor_fonte_escura;
    margin-bottom: 0px;
  }
}

// Input
.campo {
  width: 100%;
  border: none;
  border-bottom: 2px solid rgba($cor_secundaria, 0.1);
  border-radius: 0px;
  background: none;
  outline: none;
  box-shadow: none;
  height: 45px;
  padding-left: 0px;
  padding-right: 0px;
  font-family: $fonte_geral;
  font-size: 16px;
  font-weight: normal;
  color: $cor_fonte_escura;
  @include placeholder($cor_fonte_clara);
  transition: 0.3s;

  &:focus {
    border-color: $cor_primaria;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}

// Textarea
textarea.campo {
  height: auto;
  padding: 15px 0;
  resize: none;
}

// Arquivo
.campo.file {
  padding: 0px;
  border: none;
  background: none;
}

// Select
select.campo {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-ms-expand {
    display: none;
  }

  &:hover + .arrow,
  &:focus + .arrow {
    border-top-color: $cor_fonte_escura;
  }
}
// Arrow (Select)
.campo-container .arrow {
  position: absolute;
  bottom: 18px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0 5px;
  border-color: $cor_fonte_clara transparent transparent transparent;
}

// Radio / Checkbox
.campo-container.cr-container {
  width: auto;
  > label:not(.cr-lbl) {
    width: 100%;
    margin-bottom: 10px;
  }

  // Item
  .cr-lbl {
    display: inline-block;
    margin: 0px;
    margin-bottom: 5px;
    margin-right: 10px;
    padding: 5px 0;
    cursor: pointer;

    // MOBILE
    @include mq(s) {
      width: 100%;
    }

    &:last-child {
      margin-right: 0px;
    }

    input {
      display: none;
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }

    // Radio
    .radio {
      position: relative;
      display: block;
      float: left;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #ddd;
      background: #ddd;
      margin-right: 5px;
      margin-top: -2px;
      text-align: center;
      transition: 0.2s;
    }
    &:hover .radio {
      border-color: $cor_primaria;
    }
    input:checked + .radio {
      background: $cor_primaria;
      border-color: $cor_primaria;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 5.5px;
        width: 5px;
        height: 8px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(45deg);
      }
    }

    // Checkbox
    .checkbox {
      position: relative;
      display: block;
      float: left;
      width: 20px;
      height: 20px;
      border: 2px solid #ddd;
      background: #ddd;
      margin-right: 5px;
      margin-top: -2px;
      text-align: center;
      transition: 0.2s;
    }
    &:hover .checkbox {
      border-color: $cor_primaria;
    }
    input:checked + .checkbox {
      background: $cor_primaria;
      border-color: $cor_primaria;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 5.5px;
        width: 5px;
        height: 8px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(45deg);
      }
    }

    // Texto
    > span {
      display: block;
      float: left;
      width: auto;
      margin-top: -3px;
      font-size: 16px;
      font-weight: bold;

      // MOBILE
      @include mq(s) {
        float: none;
      }
    }
    input:checked ~ span {
      color: $cor_fonte_escura;
    }
    input[disabled] ~ span {
      opacity: 0.5;
      cursor: default;
    }
  }
}

// Contador de caracter
.caracter_count {
  position: absolute;
  bottom: -15px;
  right: 0;
  font-size: 14px;
  color: $cor_fonte_clara;
}

// Loading
.campo-loading {
  display: none;
  position: absolute;
  bottom: 10px;
  right: 10px;

  i {
    font-size: 24px;
    color: rgba(#000, 0.5);
  }
}
.campo-loading + .campo {
  padding-right: 50px;
}

// Validação de formulário
.show-validacao.valid::after {
  content: "\f058";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: $success_color;
  font-size: em(20);
  position: absolute;
  z-index: 2;
  bottom: 10px;
  right: 10px;
}
.parsley-errors-list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  li {
    z-index: $z_index_geral;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    display: block;
    margin-top: 10px;
    padding: 10px;
    background: $error_color;
    color: #fff;
    font-size: 14px;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    // Ponta
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -8px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent $error_color transparent;
    }
  }
}

// Recaptcha container
.recaptcha-container {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;

  // Botão
  .recaptcha-el {
    display: inline-block !important;
  }
}
