// Seção
.home-fotos {
  padding-bottom: 0;

  // Botão ver mais
  .btn-container {
    margin-top: 40px;

    // MOBILE
    @include mq(s) {
      margin-top: 20px;
    }
  }
}

// Filtros
.home-fotos-filtros {
  text-align: center;
  margin-bottom: 30px;

  // Item
  .home-fotos-filtro {
    display: inline-block;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    color: $cor_fonte_escura;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s ease-out;

    // MOBILE
    @include mq(s) {
      padding: 5px 5px;
      font-size: 12px;
    }

    &:hover,
    &.active {
      color: $cor_primaria;
    }

    &::before {
      content: "/";
      display: inline-block;
      margin-right: 30px;
      font-weight: bold;
      color: rgba(#000, 0.2);

      // MOBILE
      @include mq(s) {
        margin-right: 10px;
      }
    }
    &:first-child:before {
      display: none;
    }
  }
}

// Lista fotos
.home-fotos-lista {
  float: left;
  width: 100%;
}

// Foto item
.home-foto {
  position: relative;
  float: left;
  width: 25%;
  overflow: hidden;
  cursor: pointer;

  // TABLET
  @include mq(m) {
    width: 33.33%;
  }

  // MOBILE
  @include mq(s) {
    width: 50%;
  }

  .home-foto-inner {
    border: 1px solid #fff;
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: 0.5s ease-out;

    // TABLET
    @include mq(m) {
      height: 250px;
    }

    // MOBILE
    @include mq(s) {
      height: 170px;
    }
  }
  &:hover .home-foto-inner {
    transform: scale(1.2);
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($cor_secundaria, 0);
    transition: 0.5s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    h2 {
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      text-shadow: 2px 2px 2px rgba(#000, 0.2);
      opacity: 0;
      transform: translate3d(0, 20px, 0);
      transition: 0.5s ease-out;
      text-align: center;

      // TABLET
      @include mq(m) {
        font-size: 20px;
      }

      // MOBILE
      @include mq(s) {
        font-size: 14px;
      }
    }
  }
  &:hover span {
    background: rgba($cor_secundaria, 0.8);

    h2 {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}
