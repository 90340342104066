// Galeria caixa
.caixa-galeria {
  margin-bottom: 20px;

  // Foto
  figure {
    position: relative;
    float: left;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin-bottom: 8px;
    border-radius: 8px;
    overflow: hidden;

    img {
      transition: 0.3s ease-out;
    }

    span {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($cor_secundaria, 0);
      transition: 0.5s ease-out;

      i {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transform: scale(0.5);
        transition: 0.5s;

        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          height: 100%;
          width: 2px;
          background: rgba(#fff, 0.9);
        }
        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }
  &:hover {
    figure img {
      transform: scale(1.1);
    }
    figure span {
      background: rgba($cor_secundaria, 0.8);

      i {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  // Título
  h2 {
    float: left;
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    color: $cor_fonte_escura;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.3s ease-out;

    // MOBILE
    @include mq(s) {
      font-size: 15px;
      height: 40px;
    }

    // TABLET
    @include mq(m) {
      height: 40px;
    }
  }
  &:hover {
    h2 {
      color: $cor_primaria;
    }
  }
}

// Lista de fotos detalhe
.galeria-lista {
  float: left;
  width: 100%;
  margin-top: 20px;

  // MOBILE
  @include mq(s) {
    margin-top: 10px;
  }
}

// Galeria caixa detalhe
.galeria-thumb {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;

  img {
    transition: 0.3s ease-out;
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($cor_secundaria, 0);
    transition: 0.5s ease-out;

    i {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transform: scale(0.5);
      transition: 0.5s;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 2px;
        background: rgba(#fff, 0.9);
      }
      &::after {
        transform: rotate(90deg);
      }
    }
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
    span {
      background: rgba($cor_secundaria, 0.8);

      i {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
