// Depoimento item
.depoimento-item {
  position: relative;
  margin-bottom: 20px;

  span {
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    border-color: rgba($cor_secundaria, 0.1);
    border-style: solid;
    transition: 0.3s ease-out;
  }
  $bw: 1px;
  span:nth-child(1) {
    top: 0;
    left: 0;
    border-width: $bw 0 0 $bw;
  }
  span:nth-child(2) {
    top: 0;
    right: 0;
    border-width: $bw $bw 0 0;
  }
  span:nth-child(3) {
    bottom: 0;
    left: 0;
    border-width: 0 0 $bw $bw;
  }
  span:nth-child(4) {
    bottom: 0;
    right: 0;
    border-width: 0 $bw $bw 0;
  }
  &:hover span:nth-child(1),
  &:hover span:nth-child(4) {
    width: 100%;
  }
  &:hover span:nth-child(2),
  &:hover span:nth-child(3) {
    height: 100%;
  }
  &:hover span {
    border-color: $cor_secundaria;
  }
}

.depoimento-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($cor_secundaria, 0.7);
  opacity: 0;
  transform: scale(0.95);
  transition: 0.5s;
  transition-delay: 0.2s;
}
.depoimento-item:hover .depoimento-bg {
  opacity: 1;
  transform: scale(1);
}

.depoimento-inner {
  position: relative;
  float: left;
  width: 100%;
  padding: 40px;

  // MOBILE
  @include mq(s) {
    padding: 20px;
    text-align: center;
  }
}

// Foto
.depoimento-foto {
  float: left;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;

  // MOBILE
  @include mq(s) {
    float: none;
    display: inline-block;
  }
}

// Informações
.depoimento-infos {
  float: left;
  width: calc(100% - 120px);
  padding-left: 20px;

  // MOBILE
  @include mq(s) {
    width: 100%;
    padding-left: 0px;
    margin-top: 10px;
  }
}

// Texto
.depoimento-texto {
  font-size: 15px;
  font-weight: 300;
  //font-style: italic;
  color: $cor_fonte_escura;
  transition: 0.3s;
  transition-delay: 0.2s;

  i {
    font-size: 20px;
    color: rgba($cor_primaria, 0.5);

    // MOBILE
    @include mq(s) {
      font-size: 16px;
    }

    &.fa-quote-left {
      margin-right: 10px;
    }
    &.fa-quote-right {
      margin-left: 10px;
    }
  }
}
.depoimento-item:hover .depoimento-texto {
  color: #fff;
}

// Nome
.depoimento-nome {
  margin-top: 10px;
  font-weight: 600;
  color: $cor_fonte_escura;
  transition: 0.3s;
  transition-delay: 0.2s;
}
.depoimento-item:hover .depoimento-nome {
  color: #fff;
}
