// Header principal
.header-full {
  position: fixed;
  z-index: $z_index_header;
  top: 0;
  left: 0;
  float: left;
  width: 100%;
  background: #fff;
  padding-top: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid $cor_fundo2;
  transition: 0.5s ease;

  // TABLET
  @include mq(m) {
    text-align: center;
  }

  // MOBILE
  @include mq(s) {
    padding-bottom: 10px;
    text-align: center;
  }
}

// Conteúdo (Container)
.header-full .container {
  position: static;
}
.header-full .header-content {
  position: relative;
  padding: 0 10px;

  // MOBILE
  @include mq(s) {
    position: static;
  }
}

// Logo
.header-full .header-logo {
  display: block;
  float: left;
  max-width: 250px;

  // TABLET
  @include mq(m) {
    float: none;
    display: inline-block;
    vertical-align: top;
    max-width: 200px;
  }

  // MOBILE
  @include mq(s) {
    float: none;
    display: inline-block;
    vertical-align: top;
    max-width: 170px;
  }

  // Logo branco
  &.white-logo {
    display: none;
  }
}

// Direita
.header-full .header-direita {
  float: right;

  // MOBILE
  @include mq(s) {
    float: left;
    width: 100%;
    height: 0;
    margin-top: 0px;
  }
}

// Botões
.header-full .header-botoes {
  float: right;
  text-align: right;
  margin-left: 100px;

  // TABLET
  @include mq(m) {
    margin-left: 20px;
  }

  // MOBILE
  @include mq(s) {
    width: 100%;
    text-align: center;
    margin-left: 0px;
  }

  .btn {
    width: 150px;
    margin-left: 10px;
    font-size: 15px;

    // MOBILE
    @include mq(s) {
      width: 115px;
      font-size: 12px;
      margin: 5px;
      padding: 10px 15px;
    }
  }
}

// Idiomas
.header-langs {
  position: relative;
  float: right;
  margin-left: 50px;

  // MOBILE
  @include mq(s) {
    position: absolute;
    top: 45px;
    right: 20px;
  }
}
.header-langs-icon {
  position: relative;
  z-index: 3;
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid rgba($cor_primaria, 0.5);
  transition: 0.2s ease-out;
  cursor: pointer;

  // MOBILE
  @include mq(s) {
    width: 30px;
    height: 30px;
  }

  &:hover {
    border-color: $cor_primaria;
    transform: scale(1.05);
  }
}
.header-langs-box {
  position: absolute;
  top: 47px;
  right: -10px;
  z-index: 2;
  background: $cor_fundo2;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, -10px, 0);
  transition: 0.2s ease-out;

  // Ponta
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -8px;
    left: 19px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent $cor_fundo2 transparent;
  }
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: -15px;
    left: 0;
    width: 100%;
    height: 15px;
  }

  .header-langs-icon:not(:first-child) {
    margin-top: 10px;
  }
}
.header-langs:hover .header-langs-box {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

// Barra
.header-barra {
  height: 35px;
  margin-bottom: 20px;
  background: $cor_fundo2;
  overflow: hidden;
  transition: 0.5s ease-out;

  // MOBILE
  @include mq(s) {
    height: auto;
    padding: 7px 0;
    margin-bottom: 10px;
    background: $cor_secundaria;
  }
}

// Barra esquerda
.header-barra-left {
  float: left;
}

// Barra direita
.header-barra-right {
  float: right;

  // MOBILE
  @include mq(s) {
    width: 100%;
  }
}

// Frase
.header-barra-frase {
  padding-left: 10px;
  padding-top: 7px;
  color: $cor_fonte_escura;
  font-size: 15px;

  // TABLET
  @include mq(m) {
    font-size: 13px;
  }

  // MOBILE
  @include mq(s) {
    display: none;
  }

  b {
    font-weight: 600;
  }
}

// Redes sociais
.header-barra-redes-sociais {
  position: relative;
  float: left;
  height: 35px;
  background: rgba($cor_secundaria, 0.5);
  margin-right: 35px;

  // MOBILE
  @include mq(s) {
    display: none;
  }

  // Triângulo
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -70px;
    width: 0px;
    height: 0px;
    border-right: 35px solid rgba($cor_secundaria, 0.5);
    border-bottom: 35px solid transparent;
    border-left: 35px solid transparent;
  }

  // Barra
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -100px;
    width: 100px;
    height: 35px;
    background: rgba($cor_secundaria, 0.5);
  }

  // Item
  a {
    position: relative;
    z-index: 2;
    display: block;
    float: left;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    transition: 0.3s ease-out;

    &:hover {
      color: $cor_secundaria;
    }
  }
}

// Telefones
.header-barra-telefones {
  position: relative;
  float: left;
  padding: 0 10px;
  padding-top: 7px;
  color: #fff;
  font-size: 15px;
  height: 35px;
  background: $cor_secundaria;

  // MOBILE
  @include mq(s) {
    padding: 0px;
    width: 100%;
    height: auto;
    text-align: center;
    line-height: 100%;
    background: none;
  }

  // Triângulo
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -70px;
    width: 0px;
    height: 0px;
    border-right: 35px solid $cor_secundaria;
    border-bottom: 35px solid transparent;
    border-left: 35px solid transparent;

    // MOBILE
    @include mq(s) {
      display: none;
    }
  }

  // Barra
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -1000px;
    width: 1000px;
    height: 35px;
    background: $cor_secundaria;

    // MOBILE
    @include mq(s) {
      display: none;
    }
  }

  // Unidade
  > div {
    float: right;
    margin-left: 20px;

    // MOBILE
    @include mq(s) {
      float: none;
      display: inline-block;
      margin: 0 10px;
      font-size: 14px;
    }

    &:first-child {
      // MOBILE
      @include mq(s) {
        margin-left: 0px;
      }
    }
    &:last-child {
      margin-left: 0px;

      // MOBILE
      @include mq(s) {
        margin-right: 0px;
      }
    }
  }
  // Nome unidade
  span {
    display: inline-block;
    font-weight: 600;

    i {
      color: #fff;
      margin-right: 5px;
    }
  }
  // Telfone
  a {
    display: inline-block;
    color: #fff;
    font-weight: 600;
    transition: 0.2s ease-out;

    // MOBILE
    /*  @include mq(s) {
      color: $cor_fonte_escura;
    } */

    &:hover {
      color: $cor_primaria;
    }
  }
}

// ---------------------------- HOME ---------------------------- //
body.home-full {
  padding-top: 0px;
}

// Header
.home-full .header-full:not(.active) {
  background: none;
  background-image: linear-gradient(to bottom, rgba(#000, 0.5), rgba(#000, 0));
  box-shadow: none;
  padding-bottom: 40px;
  border: none;
}

// Logo
.home-full .header-full:not(.active) .header-logo {
  // Logo COLORIDO
  &.colored-logo {
    display: none;
  }

  // Logo BRANCO
  &.white-logo {
    display: block;
    // TABLET
    @include mq(m) {
      display: inline-block;
    }
    // MOBILE
    @include mq(s) {
      display: inline-block;
    }
  }
}

// Menu lateral
.home-full .header-full:not(.active) .lateral .fdx-menu-1-level > li > a {
  color: #fff;

  &:hover {
    color: #fff;
  }

  &:before {
    background: #fff;
  }
}

// Botão menu mobile
.home-full .header-full:not(.active) .menu-btn-mobile {
  top: 70px;
  border-color: #fff;

  &::before,
  &::after {
    background: #fff;
  }
}

// Barra
.home-full .header-full:not(.active) .header-barra {
  background: rgba(#fff, 0.1);
  margin-bottom: 40px;
}

// Frase
.home-full .header-full:not(.active) .header-barra-frase {
  color: #fff;
}

// Redes sociais
.home-full .header-full:not(.active) .header-barra-redes-sociais {
  background: rgba(#fff, 0.1);

  // Triângulo
  &::before {
    border-right: 35px solid rgba(#fff, 0.1);
  }

  // Barra
  &::after {
    background: rgba(#fff, 0.1);
    right: -1000px;
    width: 1000px;
  }

  // Item
  a {
    &:hover {
      color: $cor_primaria;
    }
  }
}

// Telefones
.home-full .header-full:not(.active) .header-barra-telefones {
  background: rgba(#fff, 0.1);

  // MOBILE
  @include mq(s) {
    background: none;
  }

  // Triângulo
  &::before {
    border-right: 35px solid rgba(#fff, 0.1);
  }

  // Barra
  &::after {
    background: rgba(#fff, 0.1);
  }
}

// Slide
.home-full .slide-item .slide-image {
  height: 100vh;
  min-height: 600px;

  // TABLET
  @include mq(m) {
    height: 90vh;
  }

  // MOBILE
  @include mq(s) {
    height: 80vh;
    min-height: 400px;
  }
}
.home-full .slide-item .slide-content {
  padding-top: 100px;
}
